body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 /*google translate Dropdown */

 #google_translate_element select {
  background: #f6edfd;
  color: #000;
  border: none;
  border-radius: 3px;
  padding: 6px 8px;
  border-radius: 24px;
}

/*google translate link | logo */
.goog-logo-link, .goog-te-gadget span, div#goog-gt- {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0;
}

/* google translate banner-frame */

.goog-te-banner-frame {
  display: none !important;
}

#goog-gt-tt, .goog-te-balloon-frame {
  display: none !important;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
 
}